<template>
  <form class="card m-auto col-12 md:col-5 lg:col-3 flex flex-column align-items-center gap-5" :action="action"
    method="post">
    <span class="mt-5 text-xl">請點擊下方驗證手機號碼</span>
    <button class="mt-2 mb-5 p-button p-button-purpply" :onclick="onClick" type="submit">完成驗證</button>
  </form>
</template>

<script>
export default {
  name: "RegisterVerificationPage",
  data() {
    return {
      action: ''
    };
  },
  created() {
    const c = this.$route.query.c
    if (!c) return;

    this.action = `https://noder.tw:4040/api/v/?c=${c}`
  },
};
</script>
